<template>
         <div class="round-trip flex-handler tw-p-5">
                <div class="tw-flex tw-flex tw-flex-col">
                    <h6 class="sizing heading look">
                        Location
                    </h6>
                    <v-text-field hide-details flat solo placeholder="Lagos"
                        prepend-inner-icon="mdi-map-marker-outline" />

                </div>
                <div class="destination sizing">
                    <span class="heading look">
                        Destination
                    </span>
                    <v-text-field hide-details flat solo placeholder="Warri" prepend-inner-icon="mdi-crosshairs-gps" />

                </div>
                <div class="departure sizing">
                    <span class="heading look">
                        Departure
                    </span>

                    <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="date" flat v-on="on" placeholder="2022-09-9" solo hide-details
                                readonly>
                                <template #prepend-inner>
                                    <v-icon class="" color="#004aad">
                                        mdi-calendar-month-outline
                                    </v-icon>
                                </template></v-text-field>
                        </template>

                        <v-date-picker locale="en-in" v-model="date" no-title @input="dateMenu = false" :min="minDate">
                        </v-date-picker>
                    </v-menu>
                </div>
                <div class="return sizing">
                    <span class="heading look">
                        Return
                    </span>

                    <v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40"
                        transition="scale-transition" offset-y max-width="290px" min-width="290px">
                        <template v-slot:activator="{ on }">
                            <v-text-field v-model="date" flat v-on="on" placeholder="2022-09-9" solo hide-details
                                readonly>
                                <template #prepend-inner>
                                    <v-icon class="" color="#004aad">
                                        mdi-calendar-month-outline
                                    </v-icon>
                                </template></v-text-field>
                        </template>

                        <v-date-picker locale="en-in" v-model="date" no-title @input="dateMenu = false" :min="minDate">
                        </v-date-picker>
                    </v-menu>
                </div>
                <div class="seats sizing">
                    <span class="heading look">
                        Seats
                    </span>
                    <select-passengers />


                </div>

            </div>
</template>

<script>
import SelectPassengers from "@/components/reuseables/SelectPassengers";
export default {
  components: {SelectPassengers},
  name: "RoundTripSearch"
}
</script>

<style lang='scss' scoped>
   .round-trip{
     display: flex;
     align-items: center;
     height: 146px;
     border: 2px solid rgba(42, 70, 108, 0.07);
     border-radius: 10px;

     @media screen and (max-width: 1024px) {
       height: max-content;
     }
   }
    .sizing {
        padding: 10px 5px;
        text-align: left;
        @media (max-width:1024px) {
            text-align: left;

        }
    }

    .look {
        font-family: 'Inter';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
    }

    .flex-handler {
        display: flex;
        flex-direction: row;
        @media (max-width:1024px) {
            flex-direction: column;
            width: 100%;
        }
    }

    .heading {
        color: #2A466C;
        font-size: 16px;

        @media (max-width:1024px) {
            text-align: left;
            padding: 10px 0 10px 50px;

        }
    }

    .v-text-field--placeholder,
    .sizing div.v-text-field input {
        font-size: 8px !important;
                background-color: #fdfffc !important; 

    }
    .v-text-field input, .v-text-field.v-text-field--solo .v-input__control input, .v-input input {
        background: #fdfffc !important;
    }
</style>