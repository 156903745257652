<template>
  <div class="tw-flex tw-w-full tw-min-h-screen tw-flex-col">

<div class="tw-flex tw-w-full tw-flex-col lg:tw-px-20 tw-px-4">
  <booking-steps />
  <round-trip-search />
  <div class="tw-flex tw-w-full tw-flex-row tw-pt-20">
    <div class="tw-flex tw-w-full lg:tw-w-1/2 tw-flex-col ">
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-pb-5">
        <h6 class="departure-text">Departure:  <span style="font-weight: 400">Available Buses</span></h6>
      </div>
      <v-expansion-panels flat>
        <select-trip-for-return-trip  :trips="departureTrips" />
      </v-expansion-panels>
    </div>
    <v-divider vertical class="tw-hidden md:tw-flex tw-mx-5" />
    <div class="tw-w-full lg:tw-w-1/2 tw-flex-col tw-hidden md:tw-flex">
      <div class="tw-flex tw-w-full tw-items-center tw-justify-between tw-pb-5">
        <h6 class="departure-text">Return:  <span style="font-weight: 400">Available Buses</span></h6>
      </div>
      <v-expansion-panels  flat>
        <select-trip-for-return-trip  :trips="returnTrips" />
      </v-expansion-panels>
    </div>
  </div>
  </div>
   <trip-details/>
  </div>
</template>

<script>
import BookingSteps from "@/components/reuseables/bookingAndHiring/OneWayBookingSteps";
import companyLogo from "@/assets/bustriptwo.png";
import SelectTripForReturnTrip from "@/components/reuseables/bookingAndHiring/SelectTripForReturnTrip";
import TripDetails from "@/components/reuseables/bookingAndHiring/TripDetails";
import RoundTripSearch from "@/views/bookings/RoundTripSearch";
export default {
  name: "ReturnTrips",
  components: {RoundTripSearch, TripDetails, SelectTripForReturnTrip, BookingSteps},
  data(){
    return{
      departureTrips:[
        {
          id : 1,
          companyLogo : companyLogo,
          companyName: "Greener Line",
          departureState: "Lagos",
          departureCity: "Yaba",
          departureTerminalAddress: "Ognenevo",
          destinationState: "Delta",
          destinationCity: "Effurun",
          destinationTerminalAddress: "44, Effurun roadabout, Effurun",
          takeOffTime : "10:30AM",
          amount : "9,900",
          seats:[
            {
              seatNumber: 1,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 2,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 3,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 4,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 5,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 6,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 7,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 8,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 9,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 10,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 11,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 12,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 13,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 14,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 15,
              seatStatus: "AVAILABLE"
            },
            {
              seatNumber: 16,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 17,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 18,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 19,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 20,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 21,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 22,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 23,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 24,
              seatstatus: "AVAILABLE"
            },

          ]
        },
        {
          id : 2,
          companyLogo : companyLogo,
          companyName: "GUO",
          departureState: "Anambra",
          departureCity: "Onitsha",
          departureTerminalAddress: "11, Old Onitsha road, Onitsha",
          destinationState: "Delta",
          destinationCity: "Effurun",
          destinationTerminalAddress: "44, Effurun roadabout, Effurun",
          takeOffTime : "11:00AM",
          amount : "10,900",
          seats:[
            {
              seatNumber: 1,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 2,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 3,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 4,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 5,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 6,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 7,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 8,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 9,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 10,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 11,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 12,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 13,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 14,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 15,
              seatStatus: "AVAILABLE"
            },
            {
              seatNumber: 16,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 17,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 18,
              seatstatus: "AVAILABLE"
            },

          ]
        },
        {
          id : 3,
          companyLogo : companyLogo,
          companyName: "GIGM",
          departureState: "Lagos",
          departureCity: "Yaba",
          departureTerminalAddress: "",
          destinationState: "Kano",
          destinationCity: "Aminu",
          destinationTerminalAddress: "44, Aminu roadabout, Aminu",
          takeOffTime : "06:30AM",
          amount : "9000",
          seats:[
            {
              seatNumber: 1,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 2,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 3,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 4,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 5,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 6,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 7,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 8,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 9,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 10,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 11,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 12,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 13,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 14,
              seatstatus: "AVAILABLE"
            },
          ]
        },
      ],

      returnTrips:[

        {
          id : 2,
          companyLogo : companyLogo,
          companyName: "GUO",
          departureState: "Anambra",
          departureCity: "Onitsha",
          departureTerminalAddress: "11, Old Onitsha road, Onitsha",
          destinationState: "Delta",
          destinationCity: "Effurun",
          destinationTerminalAddress: "44, Effurun roadabout, Effurun",
          takeOffTime : "11:00AM",
          amount : "10,900",
          seats:[
            {
              seatNumber: 1,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 2,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 3,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 4,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 5,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 6,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 7,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 8,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 9,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 10,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 11,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 12,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 13,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 14,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 15,
              seatStatus: "AVAILABLE"
            },
            {
              seatNumber: 16,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 17,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 18,
              seatstatus: "AVAILABLE"
            },

          ]
        },
        {
          id : 3,
          companyLogo : companyLogo,
          companyName: "GIGM",
          departureState: "Lagos",
          departureCity: "Yaba",
          departureTerminalAddress: "",
          destinationState: "Kano",
          destinationCity: "Aminu",
          destinationTerminalAddress: "44, Aminu roadabout, Aminu",
          takeOffTime : "06:30AM",
          amount : "9000",
          seats:[
            {
              seatNumber: 1,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 2,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 3,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 4,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 5,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 6,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 7,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 8,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 9,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 10,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 11,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 12,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 13,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 14,
              seatstatus: "AVAILABLE"
            },
          ]
        },
        {
          id : 1,
          companyLogo : companyLogo,
          companyName: "Greener Line",
          departureState: "Lagos",
          departureCity: "Yaba",
          departureTerminalAddress: "Ognenevo",
          destinationState: "Delta",
          destinationCity: "Effurun",
          destinationTerminalAddress: "44, Effurun roadabout, Effurun",
          takeOffTime : "10:30AM",
          amount : "9,900",
          seats:[
            {
              seatNumber: 1,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 2,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 3,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 4,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 5,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 6,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 7,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 8,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 9,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 10,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 11,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 12,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 13,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 14,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 15,
              seatStatus: "AVAILABLE"
            },
            {
              seatNumber: 16,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 17,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 18,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 19,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 20,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 21,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 22,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 23,
              seatstatus: "AVAILABLE"
            },
            {
              seatNumber: 24,
              seatstatus: "AVAILABLE"
            },

          ]
        },
      ],

    }
  }
}
</script>

<style scoped lang="scss">
.departure-text{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  color: #004AAD;
}
</style>