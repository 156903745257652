<template>
  <v-expansion-panels v-model="selectedTrip" flat>
  <v-expansion-panel  v-for="(trip, index) in trips" :key="index" v-on:click="clearSelected">
    <v-expansion-panel-header>
      <div class="tw-flex tw-w-full tw-flex-row tw-items-center">
        <img class="company-logo" :src="trip.companyLogo" alt="logo"/>
        <v-col  class="tw-flex tw-flex-row tw-items-center">
          <h6 class="company-name tw-ml-5 text-capitalize">{{trip.companyName}}</h6>
        </v-col>
        <v-col  class="tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">{{trip.departureState.substring(0,3)}} - {{trip.destinationState.substring(0,3)}}</h6>
        </v-col>
        <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">{{trip.takeOffTime}}</h6>
        </v-col>
        <v-col  class="tw-hidden md:tw-flex  tw-flex-row tw-items-center">
          <h6 class="trip-time tw-ml-5 text-uppercase">&#8358; {{trip.amount}}</h6>
        </v-col>
        <v-col  class="tw-hidden md:tw-flex tw-w-full tw-flex-row tw-items-center tw-justify-center">
           <v-btn text :class="[selectedTripBtn ? 'selected-btn' : 'selected-btn']" class="text-lowercase">Select</v-btn>
        </v-col>
      </div>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <div class="tw-flex tw-w-full tw-justify-center">
        <div class="tw-flex  tw-flex-col tw-w-11/12 md:tw-w-10/12 lg:tw-w-7/12">
          <div class="tw-flex md:tw-hidden tw-w-full tw-justify-between tw-py-5">
            <h6 class="trip-time text-uppercase ">{{trip.takeOffTime}}</h6>
            <h6 class="trip-time text-uppercase" style="color: #004aad; font-size: 20px">&#8358; {{trip.amount}}</h6>
          </div>
          <div class="tw-w-full tw-flex tw-items-center">
            <icons name="to-and-fro" color="#959595" class="tw-mr-5" />
            <div class="tw-w-11/12 tw-flex-col">
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center">

                <h6 class="trip-time text-uppercase ">{{trip.takeOffTime}}
                <span class="trip-time text-capitalize tw-pl-3">{{trip.departureState}} ({{trip.departureCity}} - {{trip.departureTerminalAddress}})</span>
                </h6>
                <h6 class="trip-time text-uppercase tw-hidden md:tw-flex" style="color: #004aad; font-size: 20px">&#8358; {{trip.amount}}</h6>
              </div>
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                <h6 class="trip-time text-capitalize">5 hours (Approx) 2 stops</h6>

                <h6 class="trip-time text-capitalize tw-hidden md:tw-flex" >Available: 2</h6>
              </div>
              <div class="tw-flex tw-w-full tw-justify-between tw-items-center tw-mt-8">
                <h6 class="trip-time text-uppercase ">{{trip.takeOffTime}}
                <span class="trip-time text-capitalize tw-pl-3">
                  {{trip.departureState}} ({{trip.departureCity}} - {{trip.departureTerminalAddress}})
                </span>
                </h6>
              </div>
            </div>

          </div>
          <h6 class="trip-time text-capitalize tw-flex md:tw-hidden tw-mt-5" >Available: 2</h6>
          <h6 class="tips tw-mt-10">Greener Line  .  Coaster Bus  .  Economy  . BUS 567 </h6>

          <div  class="tw-flex md:tw-hidden tw-w-full tw-flex-row tw-items-center tw-justify-end tw-my-5">
            <v-btn text :class="[selectedTripBtn ? 'selected-btn' : 'select-btn']"
                   class="text-lowercase" style="width: 240px">Select</v-btn>
          </div>

        </div>

      </div>
    </v-expansion-panel-content>
    <v-divider />
  </v-expansion-panel>

  </v-expansion-panels>
</template>

<script>

import Icons from "@/components/reuseables/Icons";

export default {
  name: "SelectTripForReturnTrip",
  components: {Icons},
  props:{
    trips: Array
  },
  data(){
    return{

      selectedTrip : [],
      selectedTripBtn: false
    }
  },
  methods:{
    clearSelected(){
      this.$store.dispatch('returnTripDepartureSeatsSelection/clearSeats')
    }
  }
}
</script>

<style scoped lang="scss">
.company-logo{
  width: 48px;
  height: 48px;
  border-radius: 50%;
}

.company-name{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: #4F4F4F;
  flex-wrap: nowrap;
}

.trip-time{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #4F4F4F;
}

.select-btn{
  border: 1px solid #004AAD;
  border-radius: 4px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #004AAD;
}

.selected-btn{
  background: #40679C;
  border: 2px solid #004AAD;
  border-radius: 4px;
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: #FFFFFF;
}

.tips{
  font-family: 'Inter',sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #878787;
}
</style>